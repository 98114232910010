import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useParams } from "react-router-dom";
import axios from 'axios';
import ReactCardFlip from 'react-card-flip';
import QRCode from 'react-qr-code';
import * as htmlToImage from 'html-to-image';
import avatar1 from "../assets/images/user-dummy-img.jpg";
import logoBTSG from "../assets/images/btsg_logo.png";
import configData from "../config.json";


const BusinessCard = () => {
    const { id } = useParams();
    const [flipDiv, setFlipDiv] = useState(false);
    const [textFlip, setTextFlip] = useState("Tap to see QR code");
    const [textButtonAction, setTextButtonAction] = useState("Add Contact");
    const [urlBusinessCard, setUrlBusinessCard] = useState("");
    const divRefContact = useRef(null);
    const [objEmp, setObjEmp] = useState({
        name_en: "",
        surname_en: "",
        nickname_en: "",
        employee_pic: "",
        id_businees_unit: 0,
        id_department: 0,
        id_position: 0,
        name_business_unit: "",
        name_department: "",
        name_position: "",
        office_email: "",
        office_phone: "",
        phone_number: ""
    });

    useEffect(() => {
        let employeeID = 0;
        try{
            employeeID = atob(id);
        }catch (error){
            employeeID = 0;
        }
        LoadDataEmp(employeeID);
        setUrlBusinessCard(configData.BUSINESS_CARD_URL + id);
    }, [id]);

    const onClickFlipDiv = () => {
        setFlipDiv(!flipDiv);
        setTextFlip((!flipDiv) ? "Back to profile" : "Tap to see QR code");
        setTextButtonAction((!flipDiv) ? "Save QR Code" : "Add Contact");
    };

    const onClickAction = async () => {
        if(flipDiv === true){
            const dataUrl = await htmlToImage.toPng(divRefContact.current, {pixelRatio: 1});
            const link = document.createElement('a');
            link.download = "BusinessCard.png";
            link.href = dataUrl;
            link.click();
        }else{
            let officePhone = ConvertOfficePhone(objEmp.office_phone);
            const vCard = [
                'BEGIN:VCARD',
                'VERSION:3.0',
                'N;CHARSET=UTF-8:' + objEmp.surname_en + ';' + objEmp.name_en + ';;;',
                'FN:' + objEmp.name_en + ' ' + objEmp.surname_en,
                'NICKNAME:' + objEmp.nickname_en,
                'TEL;TYPE=CELL:' + objEmp.phone_number,
                'TEL;TYPE=WORK:' + officePhone,
                'EMAIL:' + objEmp.office_email,
                'TITLE;CHARSET=UTF-8:' + objEmp.name_position,
                'ORG;CHARSET=UTF-8:' + objEmp.name_department,
                'END:VCARD',
            ].join('\n');

            const blobVCard = new Blob([vCard], { type: 'text/vcard'});
            const urlVCard = URL.createObjectURL(blobVCard);
            const linkVCard = document.createElement('a');
            linkVCard.download = objEmp.name_en + "_contact.vcf";
            linkVCard.href = urlVCard;
            linkVCard.click();
        }
    };

    const LoadDataEmp = (employeeID) => {
        //Call API Login B2B
        let urlLogin = configData.API_URL_AUTHEN + "login-b2b";
        let dataLogin = {
            user_name: configData.APP_USERNAME,
            password: configData.APP_PASS,
            app_key: configData.APP_KEY,
            app_secret: configData.APP_SECRET
        };
        axios.post(urlLogin, dataLogin, { responseType: "json" })
        .then(resp => {
            let respDataLogin = resp.data;
            if(respDataLogin.is_sucess === true){
                //Call API Employee
                let url = new URL(configData.API_URL + "employee-general/search");
                url.searchParams.set("status", 1);
                url.searchParams.set("empId", employeeID);
                let token = "Bearer " + respDataLogin.data.token;
                axios.get(url, { headers: { 
                    'Authorization' : token ,
                }, responseType: "json" })
                .then(resp => {
                    let respData = resp.data;
                    if(respData.is_sucess === true && respData.data.length > 0){
                        setObjEmp(respData.data[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }else{
                console.log(respDataLogin);
            }
        })
        .catch(error => {
            console.log(error);
        });
    };

    const ConvertOfficePhone = (officeNum) => {
        const officeNumLow = officeNum.toLowerCase();
        let arrOfficePhone = officeNumLow.split("-");
        if(arrOfficePhone.length > 3){
            let ext = arrOfficePhone[3].split("ext.");
            if(ext.length > 1){
                return arrOfficePhone[0] + "-" + arrOfficePhone[1] + "-" + arrOfficePhone[2] + "," + ext[1];
            }else{
                return arrOfficePhone[0] + "-" + arrOfficePhone[1] + "-" + arrOfficePhone[2];
            }
        }else{
            // return officeNumLow.replaceAll("ext.", ";ext=");
            return officeNumLow.replaceAll("ext.", ",");
        }
    };

    return (
        <React.Fragment>
            <div className="container">
                <Row>
                    <Col xl={12}>
                        <div className="d-flex justify-content-center align-items-center position-center">
                        {/* <div className="position-center"> */}
                            <Row className="business-card">
                                <Col>
                                    <ReactCardFlip isFlipped={flipDiv}>
                                        <div id="divProfile">
                                            <Card className="business-card-box" >
                                                <CardBody className="p-4">
                                                    <Row className="business-card-row">
                                                        <Col lg={12}>
                                                            <div className="business-card-row-img mb-2">
                                                                <img src={(objEmp.employee_pic !== "") ? configData.IMAGE_URL + objEmp.employee_pic : avatar1} className="business-card-img-emp rounded-circle img-thumbnail" alt="" />
                                                                <img src={logoBTSG} className="business-card-img-logo" alt=""/>
                                                            </div>
                                                            <p className="business-card-name mb-0">
                                                                {objEmp.name_en}
                                                            </p>
                                                            <p className="business-card-name mb-0">
                                                                {objEmp.surname_en}
                                                            </p>
                                                            <p className="business-card-name mb-0">
                                                                {(objEmp.nickname_en !== "") ? "(" + objEmp.nickname_en + ")" : ""}
                                                            </p>
                                                            <p className="business-card-department mb-0">
                                                                {objEmp.name_department}
                                                            </p>
                                                            <p className="business-card-department mb-2">
                                                                {objEmp.name_position}
                                                            </p>
                                                            <p className="business-card-contact mb-0">
                                                                <i className="mdi mdi-email business-card-icon me-2"></i>{(objEmp.office_email === "") ? "-" : objEmp.office_email}
                                                            </p>
                                                            <p className="business-card-contact mb-0">
                                                                <i className="mdi mdi-phone-message business-card-icon me-2"></i>{(objEmp.office_phone === "") ? "-" : objEmp.office_phone}
                                                            </p>
                                                            <p className="business-card-contact mb-0">
                                                                <i className="mdi mdi-cellphone business-card-icon me-2"></i>{(objEmp.phone_number === "") ? "-" : objEmp.phone_number}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div id="divQRCode" ref={divRefContact}>
                                            <Card className="business-card-box">
                                                <CardBody className="p-4">
                                                    <Row className="business-card-row">
                                                        <Col lg={12} className="business-card-box-qr text-center">
                                                            <div className="business-card-qr-border">
                                                                <QRCode 
                                                                    title="BTSG Business Card"
                                                                    size={256}
                                                                    value={urlBusinessCard}
                                                                    bgColor="#FFFFFF"
                                                                    fgColor="#000000"
                                                                />
                                                            </div>
                                                            <p className="business-card-scan-me mt-2">Scan me :)</p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </ReactCardFlip>
                                    <div className="text-center my-4">
                                        <p onClick={(e) => { onClickFlipDiv(); }} className="business-card-switch">{textFlip}</p>
                                    </div>
                                    <div className="text-center  mt-4">
                                        <Button color="primary" type="button" className="business-card-button" onClick={onClickAction}>
                                            {textButtonAction}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            
        </React.Fragment>
    );
};

export default BusinessCard;