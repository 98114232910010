import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MasterPage from '../layouts/master-page';
import { publicRoutes } from './route-name';

const RouteIndex = () => {
    const publicRoutesPaths = publicRoutes.map((r) => r.path);
    return (
        <React.Fragment>
            <Switch>
                <Route path={publicRoutesPaths}>
                    <MasterPage>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <Route key={idx}
                                    path={route.path}
                                    component={route.component}
                                    exact={true}
                                />
                            ))}
                        </Switch>
                    </MasterPage>
                </Route>
            </Switch>
        </React.Fragment>
    );
};

export default RouteIndex;