// import { Redirect } from 'react-router-dom';
import BusinessCard from '../pages/business-card';
import BlankPage from '../pages/blank-page';

const publicRoutes = [
    // { path: "/business-card/:id", component: BusinessCard },
    { path: "/:id", component: BusinessCard },
    { path: "/", component: BlankPage },
    // {
    //     path: "/",
    //     expect: true,
    //     component: () => <Redirect to="/blank-page" />,
    // }
];

export { publicRoutes };