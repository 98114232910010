import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const MasterPage = (props) => {
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <div className="main-content">
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
};

MasterPage.propTypes = {
    children: PropTypes.object,
};

export default withRouter(MasterPage);