import React from 'react'

const BlankPage = () => {
  return(
    <React.Fragment>
        BTS Group Holdings Public Company Limited
    </React.Fragment>
  );
}

export default BlankPage;